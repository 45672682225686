<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_workProcedureMaintenance" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row first-search-item">
            <!-- 営業所 -->
            <OfficeSelecter
              readonly
              v-model="officeSelected"
              :isRequired="true"
              :readOnry="true"
              @loading="loadingCounter = $event"
              class="search-autocomplete first-search-item"
            />
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="suppliersSelectedCode"
                :label="$t('label.lbl_supplier')"
                :hint="suppliersSelectedName"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 商品コード -->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                dense
                readonly
                v-model="productCodeSelected"
                :label="$t('label.lbl_productCnCd')"
                :hint="productSelectedName"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 承認者 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="approverSelected"
                :items="approverList"
                :label="$t('承認者')"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 担当者 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="workerSelected"
                :items="workerList"
                :label="$t('担当者')"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          :hide-default-footer="true"
        >
          <!-- 通過パターン -->
          <template v-slot:[`item.procPattern`]="{ item }">
            <div>
              {{ item.procPattern }}<br />
              {{ item.workProcedureFlg }}
            </div>
          </template>
          <!-- 通過工程1 -->
          <template v-slot:[`item.procCode1`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode1"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="1"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg1 }}
            </div>
          </template>
          <!-- 通過工程2 -->
          <template v-slot:[`item.procCode2`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode2"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="2"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg2 }}
            </div>
          </template>
          <!-- 通過工程3 -->
          <template v-slot:[`item.procCode3`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode3"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="3"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg3 }}
            </div>
          </template>
          <!-- 通過工程4 -->
          <template v-slot:[`item.procCode4`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode4"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="4"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg4 }}
            </div>
          </template>
          <!-- 通過工程5 -->
          <template v-slot:[`item.procCode5`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode5"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="5"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg5 }}
            </div>
          </template>
          <!-- 通過工程6 -->
          <template v-slot:[`item.procCode6`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode6"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="6"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg6 }}
            </div>
          </template>
          <!-- 通過工程7 -->
          <template v-slot:[`item.procCode7`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode7"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="7"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg7 }}
            </div>
          </template>
          <!-- 通過工程8 -->
          <template v-slot:[`item.procCode8`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode8"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="8"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg8 }}
            </div>
          </template>
          <!-- 通過工程9 -->
          <template v-slot:[`item.procCode9`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode9"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="9"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg9 }}
            </div>
          </template>
          <!-- 通過工程10 -->
          <template v-slot:[`item.procCode10`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode10"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="10"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg10 }}
            </div>
          </template>
          <!-- 通過工程11 -->
          <template v-slot:[`item.procCode11`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode11"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="11"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg11 }}
            </div>
          </template>
          <!-- 通過工程12 -->
          <template v-slot:[`item.procCode12`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode12"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="12"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg12 }}
            </div>
          </template>
          <!-- 通過工程13 -->
          <template v-slot:[`item.procCode13`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode13"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="13"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg13 }}
            </div>
          </template>
          <!-- 通過工程14 -->
          <template v-slot:[`item.procCode14`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode14"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="14"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg14 }}
            </div>
          </template>
          <!-- 通過工程15 -->
          <template v-slot:[`item.procCode15`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode15"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="15"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg15 }}
            </div>
          </template>
          <!-- 通過工程16 -->
          <template v-slot:[`item.procCode16`]="{ item }">
            <div>
              <input
                type="radio"
                :disabled="!item.procCode16"
                v-model="choise"
                style="transform: scale(1)"
                name="activity"
                value="16"
                @change="radioChange()"
              /><br />
              {{ item.workProcedureFlg16 }}
            </div>
          </template>
        </v-data-table>
      </v-container>
      <v-form ref="inputForm" lazy-validation style="margin-top: 20px" @submit.prevent>
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 品番 -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 15%">
                <v-text-field
                  outlined
                  dense
                  v-model="itemSelected"
                  :label="userMaler + $t('label.lbl_productCode')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                  maxlength="50"
                  :rules="[rules.inputRequired, rules.inputMinLength(itemSelected, 3)]"
                />
              </div>
              <span class="require asterisk-spacer" style="float: left">*</span>
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" id="get-search" class="api-btn" @click="getItemList()">{{
                  $t("btn.btn_search")
                }}</v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: right">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getWorkProcedureList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!-- 追加ボタン -->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">{{
                  $t("btn.btn_add")
                }}</v-btn>
                <!-- 修正選択ボタン -->
                <v-btn
                  color="primary"
                  :disabled="workProcedureList.length == 0"
                  class="api-btn"
                  v-on:click="correctionCheck"
                  >{{ $t("btn.btn_fix") + $t("btn.btn_select") }}</v-btn
                >
                <!-- 削除ボタン -->
                <v-btn
                  color="primary"
                  :disabled="workProcedureList.length == 0"
                  id="get-search"
                  class="api-btn"
                  @click="procDel()"
                  >{{ $t("削除") }}</v-btn
                >
                <!-- 登録ボタン -->
                <v-btn
                  color="primary"
                  :disabled="workProcedureList.length == 0 && !serachFlg"
                  id="get-search"
                  class="api-btn"
                  @click="procReg()"
                  >{{ $t("登録") }}</v-btn
                >
                <!-- 戻るボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="returnDpMasterRegist()"
                  >{{ $t("戻る") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid style="padding-top: 0px">
        <!-- 完成品番 -->
        <v-data-table
          id="listData"
          fixed-header
          :headers="itemHeaderItems"
          :items="itemList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          style="float: left; width: 25%"
          height="420px"
        >
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              :disabled="!item.enabledFlg"
              style="transform: scale(2)"
            />
          </template>
          <!-- 商品コード -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                  {{ item.productCnCd }}<br />
                  {{ item.accessoryProductCd }}
                </div>
              </template>
              <span
                >{{ item.productCnCd }}<br />
                {{ item.accessoryProductCd }}<br />
                {{ item.productName }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
        <!--空白-->
        <span class="spacer" style="float: left; width: 2%">&nbsp;</span>
        <!-- 作業手順 -->
        <v-data-table
          id="listData"
          fixed-header
          :headers="workProcedureHeaderItems"
          :items="workProcedureList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          style="float: right; width: 72%"
          height="420px"
          class="work-procedure-table"
        >
          <!-- 商品コード-->
          <template v-slot:item="{ item, index }">
            <tr
              :key="index"
              :id="index"
              :draggable="true"
              @dragstart="dragList($event, index)"
              @drop="dropList($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <!-- 一覧チェックボックス -->
              <td class="text-center">
                <input
                  type="checkbox"
                  v-model="item.select"
                  :value="listCheckbox"
                  style="transform: scale(2)"
                  @change="checkList(item, index)"
                />
              </td>
              <!-- 作業項目-->
              <td class="text-left">
                <div v-if="item.enabledFlg == true">
                  <v-text-field
                    class="txt-single"
                    dense
                    outlined
                    v-model="item.workItem"
                    maxlength="100"
                    clear-icon="mdi-close-circle"
                    clearable
                    persistent-hint
                    margin-bottom="0"
                    :rules="[
                      rules.datacheck(
                        item.standardWorkTime,
                        item.workItem,
                        item.workName,
                        item.checkPoint
                      ),
                    ]"
                    style="padding-top: 10px"
                  />
                </div>
                <div v-else>
                  {{ item.workItem }}
                </div>
              </td>
              <!-- 作業内容-->
              <td class="text-left">
                <div v-if="item.enabledFlg == true">
                  <v-text-field
                    class="txt-single"
                    dense
                    outlined
                    v-model="item.workName"
                    maxlength="200"
                    clear-icon="mdi-close-circle"
                    clearable
                    persistent-hint
                    margin-bottom="0"
                    style="padding-top: 10px"
                    :rules="[
                      rules.datacheck(
                        item.standardWorkTime,
                        item.workName,
                        item.workItem,
                        item.checkPoint
                      ),
                    ]"
                  />
                </div>
                <div v-else>
                  {{ item.workName }}
                </div>
              </td>
              <!-- チェック観点-->
              <td class="text-left">
                <div v-if="item.enabledFlg == true">
                  <v-text-field
                    class="txt-single"
                    dense
                    outlined
                    v-model="item.checkPoint"
                    maxlength="200"
                    clear-icon="mdi-close-circle"
                    clearable
                    persistent-hint
                    margin-bottom="0"
                    style="padding-top: 10px"
                    :rules="[
                      rules.datacheck(
                        item.standardWorkTime,
                        item.checkPoint,
                        item.workName,
                        item.workItem
                      ),
                    ]"
                  />
                </div>
                <div v-else>
                  {{ item.checkPoint }}
                </div>
              </td>
              <!-- 画像-->
              <td class="text-center">
                <v-btn
                  small
                  @click="openFileDownloadDialog(index)"
                  text
                  :disabled="item.imageList.length == 0"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <!-- アップロード-->
              <td class="text-center">
                <v-btn small @click="openFileUploadDialog(index)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <!-- 標準工数-->
              <td class="text-right">
                <div v-if="item.enabledFlg == true">
                  <v-text-field
                    class="input-number"
                    dense
                    outlined
                    v-model="item.standardWorkTime"
                    maxlength="6"
                    clear-icon="mdi-close-circle"
                    clearable
                    persistent-hint
                    margin-bottom="0"
                    @change="instCalculation(index)"
                    @click="clickPop(item.standardWorkTime)"
                  />
                </div>
                <div v-else>
                  {{ item.standardWorkTime }}
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <!-- ファイルアップロードモーダル -->
      <v-dialog v-model="isOpenFileUploadDialog" :max-width="1300" persistent no-click-animation>
        <fileUploadDialog
          :isOpenFileUploadDialog.sync="isOpenFileUploadDialog"
          v-on:parentMethod="parentMethod"
          :entity="fileUploadList"
        />
      </v-dialog>
      <!-- 画像表示 -->
      <v-dialog v-model="isOpenImage" :max-width="1000" persistent no-click-animation>
        <imageFileUploadDialog
          :isOpenImage.sync="isOpenImage"
          :entity="fileList"
          v-on:isOpenClose="isOpenClose"
        />
      </v-dialog>
      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- パターン押下時ダイアログ -->
      <v-dialog v-model="radioDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ radioMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="radioChangeBackup"> OK </v-btn>
            <v-btn @click="radioChangeReturn"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
//import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
//import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import ConfirmDialog from "@/components/ConfirmDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import OfficeSelecter from "../businessComponent/OfficeSelecter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import fileUploadDialog from "./DistributionWorkProcSeqImageFileUploadDailog";
import imageFileUploadDialog from "./DistributionWorkProcSeqImageFileDailog";

export default {
  name: appConfig.MENU_ID.P_MST_ITEM_MASTER_LIST,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    NavBar,
    OfficeSelecter,
    ConfirmDialog,
    fileUploadDialog,
    imageFileUploadDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    rules: {
      datacheck: (value, value1, value2, value3) => {
        var check1 = "";
        var check2 = "";
        var check3 = "";
        var check4 = "";
        if (value != null && value != undefined) {
          check1 = value;
        }
        if (value1 != null && value1 != undefined) {
          check2 = value1;
        }
        if (value2 != null && value2 != undefined) {
          check3 = value2;
        }
        if (value3 != null && value3 != undefined) {
          check4 = value3;
        }
        if (check1 != "" && check2 == "") {
          if (check3 == "" && check4 == "") {
            return i18n.tc("check.chk_input");
          }
        }
      },
    },
    choise: null,
    choiseBk: null,
    // ローディング画面表示フラグ
    loadingCounter: 0,

    // メニュー
    openMenu: null,

    // 営業所プルダウン
    officeSelected: sessionStorage.getItem("sales_office_sid"),
    // 取引先
    suppliersSelectedCode: "",
    suppliersSelectedName: "",
    suppliersSelected: "",
    //商品プルダウン
    productSelectedName: "",
    productCodeSelected: "",
    //受け渡し作業工程パターン
    procPattern: "",

    //承認者プルダウン
    approverSelected: "",
    approverList: [],

    //担当者プルダウン
    workerSelected: "",
    workerList: [],

    //更新日時
    updateDatetime: null,

    checkindexList: [],
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],

    inputList: [],

    // 完成品番リスト
    itemList: [],
    itemListDelete: [],
    // 作業工程リスト
    workProcedureList: [],
    deleteImageList: [],
    isDoAddButton: false,
    workProcedureFlg: false,
    serachFlg: false,

    itemSelected: "",
    registered: 0,

    //アップロードダイアログ
    isOpenFileUploadDialog: false,
    fileUploadList: {
      imageList: [],
      workSid: null,
      index: null,
    },
    //画像表示dialog
    isOpenImage: false,
    fileList: {
      imageList: [],
      deleteList: [],
      deleteImageList: [],
      index: "",
    },
    //追加ボタン押下時デフォルト
    defaultItem: {
      workSid: null, //
      select: false,
      workOrder: "", //作業順序
      workItem: "", //作業項目
      workName: "", //作業内容
      checkPoint: "", //チェック観点
      imageList: [], //画像
      upload: "", //アップロード
      standardWorkTime: "", //標準工数（秒）
      enabledFlg: true, // 有効フラグ
    },

    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    updateDialogMessage: false, // 戻るボタンダイアログ
    radioDialogMessage: false, // パターン押下時ダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    radioMessage: "", // パターン押下時ダイアログメッセージ
    // 配列のインデックス
    index: 0,
    item: "",
    userMaler: "",
    checkMessage: "",
    alertDialog: false,

    inputListItem: {
      procPattern: "",
      procCode1: false,
      procCode2: false,
      procCode3: false,
      procCode4: false,
      procCode5: false,
      procCode6: false,
      procCode7: false,
      procCode8: false,
      procCode9: false,
      procCode10: false,
      procCode11: false,
      procCode12: false,
      procCode13: false,
      procCode14: false,
      procCode15: false,
      procCode16: false,
      workProcedureFlg: "－",
      workProcedureFlg1: "－",
      workProcedureFlg2: "－",
      workProcedureFlg3: "－",
      workProcedureFlg4: "－",
      workProcedureFlg5: "－",
      workProcedureFlg6: "－",
      workProcedureFlg7: "－",
      workProcedureFlg8: "－",
      workProcedureFlg9: "－",
      workProcedureFlg10: "－",
      workProcedureFlg11: "－",
      workProcedureFlg12: "－",
      workProcedureFlg13: "－",
      workProcedureFlg14: "－",
      workProcedureFlg15: "－",
      workProcedureFlg16: "－",
      procCd1: "",
      procCd2: "",
      procCd3: "",
      procCd4: "",
      procCd5: "",
      procCd6: "",
      procCd7: "",
      procCd8: "",
      procCd9: "",
      procCd10: "",
      procCd11: "",
      procCd12: "",
      procCd13: "",
      procCd14: "",
      procCd15: "",
      procCd16: "",
    },
    defaultData: [], // 初期データ

    // テーブルヘッダ項目
    headerItems: [
      // 通過パターン
      {
        text: i18n.tc("label.lbl_passingPattern"),
        value: "procPattern",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 通過工程1
      {
        text: "",
        value: "procCode1",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程2
      {
        text: "",
        value: "procCode2",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程3
      {
        text: "",
        value: "procCode3",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程4
      {
        text: "",
        value: "procCode4",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程5
      {
        text: "",
        value: "procCode5",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程6
      {
        text: "",
        value: "procCode6",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程7
      {
        text: "",
        value: "procCode7",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程8
      {
        text: "",
        value: "procCode8",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程9
      {
        text: "",
        value: "procCode9",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程10
      {
        text: "",
        value: "procCode10",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程11
      {
        text: "",
        value: "procCode11",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程12
      {
        text: "",
        value: "procCode12",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程13
      {
        text: "",
        value: "procCode13",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程14
      {
        text: "",
        value: "procCode14",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程15
      {
        text: "",
        value: "procCode15",
        width: "6%",
        align: "center",
        sortable: false,
      },
      // 通過工程16
      {
        text: "",
        value: "procCode16",
        width: "6%",
        align: "center",
        sortable: false,
      },
    ],
    // テーブルヘッダ項目
    itemHeaderItems: [
      // 同一手順
      {
        text: "同一\n手順",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 完成品番
      {
        text: "完成品番",
        value: "productCnCd",
        width: "30%",
        align: "left",
        sortable: false,
      },
    ],
    // テーブルヘッダ項目
    workProcedureHeaderItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_select"),
        value: "select",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 作業項目
      {
        text: i18n.tc("label.lbl_work") + i18n.tc("label.lbl_items"),
        value: "workItem",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 作業内容
      {
        text: i18n.tc("label.lbl_work") + i18n.tc("label.lbl_content"),
        value: "workName",
        width: "25%",
        align: "left",
        sortable: false,
      },
      // チェック観点
      {
        text: i18n.tc("label.lbl_checkPoint"),
        value: "checkPoint",
        width: "25%",
        align: "left",
        sortable: false,
      },
      // 画像
      {
        text: i18n.tc("label.lbl_image"),
        value: "image",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // アップロード
      {
        text: i18n.tc("label.lbl_up_load"),
        value: "upload",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 標準工数
      {
        text: i18n.tc("label.lbl_standardManHours"),
        value: "standardWorkTime",
        width: "7%",
        align: "right",
        sortable: false,
      },
    ],
    authorizList: [],
    authorizDataList: [],
  }),
  methods: {
    /**
     * ドラッグ
     */
    dragList(event, dragIndex) {
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex);
    },
    /**
     * ドロップ
     */
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = this.workProcedureList.splice(dragIndex, 1);
      this.workProcedureList.splice(dropIndex, 0, deleteList[0]);
    },
    // 初期化
    init() {
      //初期情報保持処理
      // 初期表示の設定
      this.defaultData = Object.assign({}, this.$data);
      // 前画面からのパラメータ設定
      this.suppliersSelectedCode = this.$route.params.suppliersSelectedName;
      this.suppliersSelectedName = this.$route.params.clientName;
      this.productCodeSelected = this.$route.params.productSelectedName;
      this.productSelectedName = this.$route.params.itemNm;
      this.suppliersSelected = this.$route.params.suppliersSelected;
      this.procPattern = this.$route.params.procPattern;
      this.updateDatetime = this.$route.params.updateDatetime;
      this.itemList = [];
      this.inputList = [];
      this.workProcedureList = [];
      this.checkindexList = [];
      this.itemListDelete = [];
      this.deleteImageList = [];
      //名称セット
      this.userMaler = this.$route.params.userMaler;
      this.getProcCode();
      //リスト項目取得
      this.getCodeList();
      this.getInitialItemList();
    },
    //コードマスタ取得
    getCodeList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 承認者情報取得
      const authorizerList = getParameter.getCodeMst(appConfig.CODETYPE.CODE_AUTHORIZER);
      return Promise.all([authorizerList])
        .then((result) => {
          this.authorizList = result[0];
          if (this.authorizList.length > 0) {
            for (let i = 0; i < this.authorizList.length; i++) {
              this.authorizDataList.push(this.authorizList[i].name);
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          this.getManagerList();
        });
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 工程クリック時
     */
    radioChange() {
      //作業手順Listが1件以上あるかチェック
      if (this.workProcedureList.length > 0 || this.serachFlg) {
        this.radioDialogMessage = true;
        this.radioMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      } else {
        this.choiseBk = this.choise;
        this.serachFlg = false;
      }
    },
    radioChangeBackup() {
      this.choiseBk = this.choise;
      this.workProcedureList = [];
      this.radioDialogMessage = false;
      this.workProcedureFlg = false;
      this.serachFlg = false;
    },
    radioChangeReturn() {
      this.choise = this.choiseBk;
      this.radioDialogMessage = false;
    },
    /**
     * カンマ区切り対応
     */
    instCalculation(val) {
      this.workProcedureList[val].standardWorkTime = commonFunction.getDelimiter(
        this.workProcedureList[val].standardWorkTime,
        5
      );
    },
    //リスト項目取得処理
    getManagerList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 担当者
      const userBizList = getParameter.getUserBiz();
      //承認者
      const userBizList1 = getParameter.getUserBiz(this.authorizDataList);
      Promise.all([userBizList, userBizList1])
        .then((result) => {
          this.workerList = result[0];
          this.approverList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 登録ボタン押下
     */
    procReg() {
      if (this.$refs.form.validate()) {
        this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.procRegInsert;
      }
    },

    /**
     * OKボタン押下時(登録POST)
     */
    procRegInsert() {
      //登録
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_009;
      //
      body.reqIdv.clientSid = this.$route.params.supplier;
      if (this.inputList[0]["workProcedureFlg" + this.choise] == "登録済み") {
        body.reqIdv.updateKbn = "1";
      } else {
        body.reqIdv.updateKbn = "0";
      }
      if (this.$route.params.workContentSid) {
        body.reqIdv.workContentSid = this.$route.params.workContentSid;
      } else {
        body.reqIdv.workContentSid = null;
      }
      body.reqIdv.procCd = this.inputList[0]["procCd" + this.choise];
      body.reqIdv.applyUserSid = this.workerSelected;
      body.reqIdv.approveUserSid = this.approverSelected;
      body.reqIdv.procPattern = this.$route.params.procPattern;
      let workSeqList = [];
      let workSeqItemList = [];
      let sameWorkSeqItemCancelList = [];
      if (this.registered == 0 && this.workProcedureList.length == 0) {
        // 更新対象の品番
        this.itemListDelete.push({
          itemStandardSid: this.$route.params.productCodeSelected,
          updateDatetime: this.updateDatetime,
        });
        // 同一品番セット
        this.itemList.forEach((row) => {
          if (row.check) {
            this.itemListDelete.push({
              itemStandardSid: row.itemStandardSid,
              updateDatetime: row.updateDatetime,
            });
          }
        });
      } else {
        // 更新対象の品番
        workSeqItemList.push({
          itemStandardSid: this.$route.params.productCodeSelected,
          updateDatetime: this.updateDatetime,
        });
        // 同一品番セット
        this.itemList.forEach((row) => {
          if (row.check) {
            workSeqItemList.push({
              itemStandardSid: row.itemStandardSid,
              updateDatetime: row.updateDatetime,
            });
          }
        });
      }
      const listDelete = this.itemList.filter((element) => {
        return element.initialFlg && !element.check;
      });

      listDelete.forEach((row) => {
        this.itemListDelete.push({
          itemStandardSid: row.itemStandardSid,
          updateDatetime: row.updateDatetime,
        });
      });
      sameWorkSeqItemCancelList = this.itemListDelete;
      for (let i = 0; i < this.workProcedureList.length; i++) {
        if (
          this.workProcedureList[i].workItem ||
          this.workProcedureList[i].workName ||
          this.workProcedureList[i].checkPoint ||
          this.workProcedureList[i].standardWorkTime
        ) {
          let workContentImageList = [];
          for (let j = 0; j < this.workProcedureList[i].imageList.length; j++) {
            workContentImageList.push({
              workContentImgSid: this.workProcedureList[i].imageList[j].imageSid,
              url: this.workProcedureList[i].imageList[j].url,
              fileName: this.workProcedureList[i].imageList[j].fileName,
              fileData: this.workProcedureList[i].imageList[j].base64,
            });
          }
          let workItemSet = "";
          let workNameSet = "";
          let checkPointSet = "";
          if (this.workProcedureList[i].workItem) {
            workItemSet = this.workProcedureList[i].workItem;
          }
          if (this.workProcedureList[i].workName) {
            workNameSet = this.workProcedureList[i].workName;
          }
          if (this.workProcedureList[i].checkPoint) {
            checkPointSet = this.workProcedureList[i].checkPoint;
          }
          workSeqList.push({
            workOrder: i + 1,
            workName: workItemSet + "\t" + workNameSet + "\t" + checkPointSet,
            standardEffort: String(this.workProcedureList[i].standardWorkTime).replace(
              /[^0-9]/gi,
              ""
            ),
            workContentImageList: workContentImageList,
          });
        }
      }
      body.reqIdv.workSeqList = workSeqList;
      body.reqIdv.workSeqItemList = workSeqItemList;
      body.reqIdv.sameWorkSeqItemCancelList = sameWorkSeqItemCancelList;
      body.reqIdv.deleteImageList = this.deleteImageList;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INIT_DP_WORK_SEQ_INSERT, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == "000") {
              // 成功
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_insert"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              this.$route.params.workContentSid = jsonData.resIdv.workContentSid;
              //初期化処理
              this.clearScreenOkClick(body.reqCom.reqComExecTimestamp);
            } else {
              // 失敗
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },
    /**
     * 初期化処理
     */
    clearScreenOkClick(val) {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.$route.params.updateDatetime = val;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.$refs.inputForm.resetValidation();
    },
    //削除ボタン押下時
    procDel() {
      if (this.checkindexList.length == 0) {
        //エラーメッセージ表示
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("label.lbl_rowDelete"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.procDeltele;
    },
    // 削除処理
    procDeltele() {
      for (let i = 0; i < this.workProcedureList.length; i++) {
        if (this.workProcedureList[i].select) {
          for (let j = 0; j < this.workProcedureList[i].imageList.length; j++) {
            if (this.workProcedureList[i].imageList[j].url != "") {
              this.deleteImageList.push(this.workProcedureList[i].imageList[j].url);
            }
          }
        }
      }

      //選択されている行削除
      this.workProcedureList = this.workProcedureList.filter((element) => {
        return !element.select;
      });
    },
    //同一品番初期表示
    getInitialItemList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();

      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      //取引先SID
      config.params.clientSid = this.$route.params.supplier;
      //作業手順SID
      config.params.workContentSid = this.$route.params.workContentSid;
      //通過工程パターン
      config.params.procPattern = this.$route.params.procPattern;
      //商品標準SID
      config.params.itemStandardSid = this.$route.params.productCodeSelected;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INIT_DP_SAME_WORK, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.sameWorkSeqItemList.forEach((row) => {
                this.itemList.push({
                  check: true, //選択
                  enabledFlg: true, // 有効フラグ
                  clientSid: this.$route.params.supplier, //取引先SID
                  itemStandardSid: row.itemStandardSid, //商品Sid
                  productCnCd: row.itemCd, //品番
                  accessoryProductCd: row.itemSubCd, //付帯品番
                  productName: row.itemName, //品名
                  updateDatetime: row.updateDatetime, //更新日時
                  initialFlg: true,
                });
              });
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 検索ボタン押下後、商品マスタ一覧を取得
    getItemList() {
      //バリデーションチェック
      if (this.$refs.inputForm.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        //初期表示している情報のチェックを外した場合
        const listDelete = this.itemList.filter((element) => {
          return element.initialFlg && !element.check;
        });

        listDelete.forEach((row) => {
          this.itemListDelete.push({
            itemStandardSid: row.itemStandardSid,
            updateDatetime: row.updateDatetime,
          });
        });

        //チェックされているデータ以外削除
        const list = this.itemList.filter((element) => {
          return element.check;
        });
        this.itemList = list;

        const config = this.$httpClient.createGetApiRequestConfig();

        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        //ユーザーメーカー区分
        config.params.userMakerKbn = this.$route.params.userMakerKbn;
        // 取引先SID
        config.params.clientSid = this.$route.params.supplier;
        //品番
        config.params.itemCd = this.itemSelected;
        //工程パターン
        config.params.procPattern = this.$route.params.procPattern;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.INI_DP_MASTER_REGIST_STATUS_LIST, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 成功
              if (jsonData.resCom.resComCode == "000") {
                jsonData.resIdv.distributionProcessingStateList.forEach((row) => {
                  if (row.itemStandardSid != this.$route.params.productCodeSelected) {
                    const itemCheck = this.itemList.some((element) => {
                      return element.itemStandardSid == row.itemStandardSid;
                    });
                    if (!itemCheck || this.itemList.length == 0) {
                      this.itemList.push({
                        check: false, //選択
                        enabledFlg: true, // 有効フラグ
                        clientSid: row.clientSid, //取引先SID
                        itemStandardSid: row.itemStandardSid, //商品Sid
                        productCnCd: row.itemCd, //品番
                        accessoryProductCd: row.itemSubCd, //付帯品番
                        productName: row.itemName, //品名
                        initialFlg: false,
                        updateDatetime: row.updateDatetime, //更新日時
                      });
                    }
                  }
                });
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * アップロード画面を登録した際
     */
    parentMethod() {
      const val = this.fileUploadList.index;
      //データセット
      this.workProcedureList[val].imageList = this.fileUploadList.imageList;
    },
    isOpenClose() {
      const index = this.fileList.index;
      let imageListResiue = this.workProcedureList[index].imageList;
      //削除する画像をセット
      if (this.fileList.deleteList.length > 0) {
        this.fileList.deleteList.forEach((row) => {
          //画像Listから削除
          imageListResiue = imageListResiue.filter((element) => {
            return row.url != element.url;
          });
          this.deleteImageList.push(row.url);
        });
      }
      if (this.fileList.deleteImageList.length > 0) {
        this.fileList.deleteImageList.forEach((row) => {
          //画像Listから削除
          imageListResiue = imageListResiue.filter((element) => {
            return row.fileName != element.fileName;
          });
        });
      }
      this.workProcedureList[index].imageList = imageListResiue;
    },
    // 検索ボタン押下後、作業手順一覧を取得
    getWorkProcedureList() {
      //選択済みかチェック
      if (this.choise == null) {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-INI-009_001_E");
        return;
      }
      //ステータスが未登録かチェック
      if (this.inputList[0]["workProcedureFlg" + this.choise] == "未登録") {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-INI-009_002_E");
        return;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;
      //作業手順取得API
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.procCd = this.inputList[0]["procCd" + this.choise];

      //作業手順SID
      config.params.workContentSid = this.$route.params.workContentSid;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INIT_DP_WORK_SEQ, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              //
              const list = [];
              this.workProcedureFlg = true;
              let i = 1;
              jsonData.resIdv.workSeqList.forEach((row) => {
                if (i == 1) {
                  //承認者
                  this.approverSelected = row.approveUserSid;
                  //処理者
                  this.workerSelected = row.applyUserSid;
                  i++;
                }
                const workNameList = row.workName.split("\t");
                //画像リスト追加
                const imageList = [];
                row.workContentImgList.forEach((imageRow) => {
                  imageList.push({
                    imageSid: imageRow.workContentImgSid, //画像SID
                    base64: null, //画像
                    url: imageRow.url, //画像URL
                    fileName: null, //ファイル名
                  });
                });
                list.push({
                  workSid: this.$route.params.workContentSid, //
                  select: false,
                  workOrder: row.workOrder, //作業順序
                  workItem: workNameList[0], //作業項目
                  workName: workNameList[1], //作業内容
                  checkPoint: workNameList[2], //チェック観点
                  imageList: imageList, //画像
                  upload: row.upload, //アップロード
                  standardWorkTime: row.standardEffort, //標準工数（秒）
                  enabledFlg: false, // 有効フラグ
                });
              });
              this.workProcedureList = list;
              if (this.workProcedureList.length > 0) {
                this.serachFlg = true;
              }
              this.checkindexList = [];
              this.deleteImageList = [];
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    alertCancel() {
      //dialog非表示
      this.alertDialog = false;
    },

    /**
     * ファイルアップロードダイアログを表示
     */
    openFileUploadDialog(index) {
      this.fileUploadList.workSid = this.workProcedureList[index].workSid;
      this.fileUploadList.imageList = this.workProcedureList[index].imageList;
      this.fileUploadList.index = index;
      this.isOpenFileUploadDialog = true;
    },

    /**
     * 画像確認dialogを表示
     */
    openFileDownloadDialog(index) {
      this.fileList.imageList = this.workProcedureList[index].imageList;
      this.fileList.index = index;
      this.isOpenImage = true;
    },

    getProcCode() {
      //通過工程情報取得処理
      const config = this.$httpClient.createGetApiRequestConfig();

      //取引先SID
      config.params.clientSid = this.$route.params.supplier;
      //工程パターン
      config.params.procPattern = this.$route.params.procPattern;
      //作業手順SID
      config.params.workContentSid = this.$route.params.workContentSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.INI_DP_WORK_SEQ_PROC_PATTER, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              this.inputList.push(Object.assign({}, this.inputListItem));
              //工程パターンセット
              this.inputList[0].procPattern = this.$route.params.procPattern;
              let i = 1;
              jsonData.resIdv.procPatternList.forEach((row) => {
                //工程名セット
                this.headerItems[i].text = row.procName;
                this.inputList[0]["procCd" + i] = row.procCd;
                if (!row.procCd.endsWith("9")) {
                  this.inputList[0]["workProcedureFlg" + i] =
                    row.workContentSid != null ? "登録済み" : "未登録";
                  this.inputList[0]["procCode" + i] = true;
                }
                i++;
                if (row.workContentSid != null) {
                  this.registered++;
                }
              });

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 追加ボタン押下時
     */
    add() {
      //選択済みかチェック
      if (this.choise == null) {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-INI-009_001_E");
        return;
      }
      //登録済みか未登録かチェック
      if (
        this.inputList[0]["workProcedureFlg" + this.choise] == "登録済み" &&
        !this.workProcedureFlg
      ) {
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-INI-009_003_E");
        return;
      }
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;
      //行追加
      for (var i = 0; i < 10; i++) {
        this.workProcedureList.push({
          workSid: null, //
          select: false,
          workOrder: this.workProcedureList.length + i + 1, //作業順序
          workItem: "", //作業項目
          workName: "", //作業内容
          checkPoint: "", //チェック観点
          imageList: [], //画像
          upload: "", //アップロード
          standardWorkTime: "", //標準工数（秒）
          enabledFlg: true, // 有効フラグ
        });
      }

      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },
    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },
    /**
     * 修正選択ボタン押下時
     */
    correctionCheck() {
      //チェックボックスの件数チェック
      if (this.checkindexList.length == 0) {
        //エラーメッセージ表示
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
      //検索フラグ更新
      this.serchFlag = false;
      this.updateFlg = true;
      //対象行修正可能に変更
      for (let i = 0; i < this.checkindexList.length; i++) {
        this.workProcedureList[this.checkindexList[i]].enabledFlg = true;
      }
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (item.select) {
        this.checkindexList.push(index);
      } else {
        this.checkindexList = this.checkindexList.filter(function (items) {
          return items !== index;
        });
      }
    },
    //戻るボタン押下時
    returnDpMasterRegist() {
      //戻るボタンダイアログ表示
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;
      // 一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_INI_008,
        params: {
          userMakerKbn: this.$route.params.userMakerKbn, // ユーザーメーカー区分
          supplier: this.$route.params.supplier, //取引先
          itemCd: this.$route.params.itemCd, //品番
          itemSubCd: this.$route.params.itemSubCd, //付帯品番
          name: appConfig.SCREEN_ID.P_INI_009,
        },
      });
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.v-data-table {
  white-space: pre-line;
}

#listProcData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 4px;
}

.v-text-field.input-number {
  margin: 0;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
</style>
